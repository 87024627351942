<template>
  <div class="apple-pay">
    <h2>Buy with Apple&nbsp;Pay</h2>
    <p>Compatible browsers will display an Apple&nbsp;Pay button below.</p>
    <div
      class="apple-pay-button"
      @click="applePayButtonClicked"
    >
      Оплатить
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ApplePay',
  data() {
    return {
      amount: 300,
      fee: 5,
      sector: 3032,
      resultType: 'Xml',
      preauth: 'N',
      currency: 'RUB',
      reference: 'N67589',
      language: 'RUS',
    }
  },
  mounted() {
    //1 ApplePay: Отображение кнопки, если браузер поддерживает ApplePay
    if (window.ApplePaySession) {
      console.log('window.ApplePaySession')
      if (ApplePaySession.canMakePayments) {
        this.showApplePayButton()
        console.log('ApplePaySession.canMakePayments')
      }
    }
  },
  methods: {
    async getApplePaySession(url, session) {
      console.log('getApplePaySession')
      const data = {
        url: url,
      }
      var config = {
        headers: { 'Content-Type': 'text/xml' },
      }

      // console.log('response2', response2)
      //
      return new Promise(function (resolve, reject) {
        axios
          .post('https://tapper.kirill.zedform.ru/api/best2pay/applepay', data)
          .then((response) => {
            const one = response.data.split('<tokenBody>')
            const two = one[1].split('</tokenBody>')
            const tokenBody = JSON.parse(two[0])
            resolve(tokenBody)
          })
        // $.ajax({
        // url: 'https://test.best2pay.net/webapi/GetApplePaySession', type: "POST",
        // data: {
        //   url: url,
        //   sector: sector
        // },
        // dataType: 'xml',
        // success: function (xml) {
        //   var tokenBody;
        //   var result=false;
        //   $(xml).find('token').each(function(){
        //     result=true;
        //     tokenBody = $(this).find('tokenBody').text(); });
        //     if (result){ resolve(JSON.parse(tokenBody));
        //     } else {
        //       session.abort(); }
        //     },
        //     error:function () {
        //       session.abort();
        //     }
        // });
      })
    },

    //0 ApplePay: Отображение кнопки, если браузер поддерживает ApplePay
    showApplePayButton() {
      HTMLCollection.prototype[Symbol.iterator] =
        Array.prototype[Symbol.iterator]
      const buttons = document.getElementsByClassName('apple-pay-button')
      for (let button of buttons) {
        button.className += 'visible'
      }
    },

    //2 ApplePay: Клик по кнопке
    applePayButtonClicked() {
      const paymentRequest = {
        requiredShippingContactFields: ['email', 'phone'],
        countryCode: 'RU',
        currencyCode: this.currency,
        supportedNetworks: ['visa', 'masterCard'],
        merchantCapabilities: ['supports3DS'],
        lineItems: [
          {
            type: 'final',
            label: 'Сумма платежа',
            //Для wallet указывается в рублях amount: amount/100
            amount: this.amount / 100,
          },
          {
            type: 'final',
            label: 'Комиссия',
            //Для wallet указывается в рублях
            amount: this.fee / 100,
          },
        ],
        total: {
          label: 'Сумма с комиссией',
          //Для wallet указывается в рублях
          amount: (this.amount + this.fee) / 100,
        },
      }

      const session = new ApplePaySession(1, paymentRequest)

      session.onvalidatemerchant = (event) => {
        this.getApplePaySession(event.validationURL, session).then(function (
          response
        ) {
          console.log('res', response)
          session.completeMerchantValidation(response)
        })
      }

      session.onpaymentauthorized = async (event) => {
        let paymentToken = JSON.stringify(event.payment.token)
        let shippingContact = JSON.stringify(event.payment.shippingContact)
        let status

        const data = {
          sector: this.sector,
          preauth: this.preauth,
          amount: this.amount,
          paymentToken: paymentToken,
          shippingContact: shippingContact,
          fee: this.fee,
          desc: 'this.description',
          language: this.language,
        }

        await axios
          .post(
            'https://tapper.kirill.zedform.ru/api/best2pay/applepayfinal',
            data
          )
          .then((response) => {
            console.log('data', data)
            console.log('response', response)
            status = ApplePaySession.STATUS_SUCCESS
            session.completePayment(status)
          })
          .catch((e) => {
            status = ApplePaySession.STATUS_FAILURE
            session.completePayment(status)
          })

        //   $.ajax({
        //     url: 'https://test.best2pay.net/webapi/MakeApplePay',
        //     type: "POST",
        //     data: {
        //       sector: sector,
        //       preauth: preauth,
        //       amount: amount,
        //       paymentToken: paymentToken,
        //       shippingContact: shippingContact,
        //       fee: fee,
        //       desc: description,
        //       language: language
        //     },
        //     dataType: 'xml',
        //     success: function (response) {
        //       status = ApplePaySession.STATUS_SUCCESS
        //       session.completePayment(status)
        //     },
        //     error:function(num) {
        //       //Ошибки запроса
        //       status = ApplePaySession.STATUS_FAILURE
        //       session.completePayment(status)
        //     }
        //   })
      }
      session.begin()
    },
  },
}
</script>
<style lang="scss">
.apple-pay-button {
  display: none;
}
.apple-pay-buttonvisible {
  display: inline-flex;
  border: 1px solid red;
  width: 300px;
  height: 50px;
  margin: 10px;
  justify-content: center;
  align-items: center;
}
</style>
